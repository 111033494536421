import React from 'react';
import ReactDOM from 'react-dom/client';
import './style_1.css'; // Import your styles
import App from './App';

// function App(){
//   return ( 
//     <h1> This is tipzonn React</h1>
//   )
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


